<template>
	<b-modal return-focus=".view-title" id="modal-1" title="수업 등록하기" role="tabpanel" @hide="initData()">
		<b-row>
			<b-col class="view-description">
				수업 예정일시 및 길이(월페커즈 게임 진행 시간)를 등록해주세요. 수업 진행용 프레스코드는 당일 수업 상세보기에서 확인할 수 있습니다. 수업을 여러 번 진행할 경우 필요한 만큼 예약하시면 됩니다.
			</b-col>
		</b-row>
		<b-row class="modal-label">
			<b-col>
				과목
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-form-select class="input" v-model="classType" :text="classType">
					<b-form-select-option value="0" disabled>-- 과목 선택 --</b-form-select-option>
					<b-form-select-option v-for="game in gameTypes" v-bind:key="game.id" :value="game.id">{{ game.title }}</b-form-select-option>
				</b-form-select>
			</b-col>
		</b-row>
		<b-row class="modal-label">
			<b-col>
				학년
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-form-select class="input" v-model="gradeYear">
					<b-form-select-option value="" disabled>-- 학년 선택 --</b-form-select-option>
					<b-form-select-option v-for="(opt, i) in gradeYearOptions" :key="i" :value="opt">{{ opt }}</b-form-select-option>
				</b-form-select>
			</b-col>
		</b-row>
		<b-row class="modal-label">
			<b-col>
				수업 제목 (선택사항)
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-form-input class="input" v-model="classTitle" placeholder="수업 제목, 반 이름 등을 메모할 수 있습니다."></b-form-input>
			</b-col>
		</b-row>
		<b-row class="modal-label">
			<b-col>
				날짜
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-form-datepicker
					class="input"
					v-model="startDate"
					:min="today"
					hide-header
					label-prev-year="이전 해"
					label-prev-month="이전 달"
					label-current-month="이번 달"
					label-next-month="다음 달"
					label-next-year="다음 해"
					label-help=""
					placeholder="수업을 진행할 날짜를 선택해주세요."
				></b-form-datepicker>
			</b-col>
		</b-row>
		<b-row class="modal-label">
			<b-col>
				수업 시작 시간
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<VueCtkDateTimePicker v-model="startTime" label="수업을 시작할 시각을 선택해주세요." only-time minute-interval="10" no-header format="HH:mm:ss" formatted="a HH:mm" />
			</b-col>
		</b-row>
		<b-row class="modal-label">
			<b-col>
				게임 길이
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-form-select class="input" v-model="playTime">
					<template v-slot:first>
						<b-form-select-option value="" disabled>-- 30분, 40분 중 택일 --</b-form-select-option>
					</template>
					<!--           <b-form-select-option value='5'>
            5분
          </b-form-select-option> -->
					<b-form-select-option value="30">
						30분
					</b-form-select-option>
					<b-form-select-option value="40">
						40분
					</b-form-select-option>
				</b-form-select>
			</b-col>
		</b-row>

		<template v-slot:modal-footer>
			<b-row align-h="center">
				<b-col class="text-center">
					<b-button v-if="!applyCheck || buttonPressed" disabled> <b-spinner v-if="buttonPressed" small /> 수업 등록 </b-button>
					<b-button class="orange" v-if="applyCheck && !buttonPressed" v-on:click="postSession">수업 등록</b-button>
				</b-col>
			</b-row>
			<b-row v-if="isApplied">
				<b-col>수업이 성공적으로 등록되었습니다.</b-col>
			</b-row>
		</template>
	</b-modal>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

export default {
	data() {
		const now = new Date();
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		return {
			// 세션 신청 데이터
			classType: 0,
			classTitle: '',
			startDate: '',
			startTime: `${now.getHours()}:${now.getMinutes()}`,
			playTime: '',
			gradeYear: '',
			isRemote: false,
			isApplied: false,
			buttonPressed: false,
			today: today,
			gameTypes: [
				{ id: 1, title: '월페커즈 인스쿨(교실수업용)' },
				{ id: 3, title: '월페커즈 앳홈(원격수업용)' },
				{ id: 4, title: '영릉 시간탐험대' }, // TODO 여주 추가 할 때 풀기
				{ id: 5, title: '월페커즈 메타버스' },
			],
			// 2021-03 현재 '월페커즈 (앳홈)'만 is_remote에 해당
			// 2022-04 모두 remote에 해당
			remoteGameTypes: [1, 3],
			gradeYearOptions: ['초1', '초2', '초3', '초4', '초5', '초6', '중1', '중2', '중3', '고1', '고2', '고3'],
		};
	},
	computed: {
		// 필수 선택 처리
		classTypeValidation() {
			return this.classType !== 0;
		},
		// 필수 입력 처리
		startDateValidation() {
			return this.startDate.length > 0;
		},
		startTimeValidation() {
			return this.startTime.length > 0;
		},
		gradeYearValidation() {
			return this.gradeYear.length > 0;
		},
		// 필수 선택 처리
		playTimeValidation() {
			return this.playTime === '30' || this.playTime === '40' || this.playTime === '5';
		},
		// 세션 신청 완료되었는지 확인 후 안내
		applyCheck() {
			return this.classTypeValidation && this.startDateValidation && this.startTimeValidation && this.playTimeValidation && this.gradeYearValidation;
		},
	},
	methods: {
		// 신청 데이터 초기화
		initData() {
			const now = new Date();
			this.classType = 0;
			this.startDate = '';
			this.startTime = `${now.getHours()}:${now.getMinutes()}`;
			this.classTitle = '';
			this.playTime = '';
			this.gradeYear = '';
			(this.isRemote = false), (this.isApplied = false);
		},
		getGameTypes() {
			this.$store
				.dispatch('GET_GAME_TYPES')
				.then((res) => {
					this.gameTypes = res.data.results.filter((g) => g.title !== '건축놀이사무소');
					// console.log(this.gameTypes)
				})
				.catch((e) => e);
		},
		// 세션 신청 요청
		postSession() {
			// console.log(this.startTime)
			this.buttonPressed = true;
			setTimeout(() => {
				this.buttonPressed = false;
			}, 300);
			this.$store
				.dispatch('POST_SESSION', {
					start_date: this.startDate + 'T' + this.startTime + '+09:00',
					play_time: this.playTime,
					title: this.classTitle,
					class_type: this.classType,
					// 2022-04 항상 remote로 수정
					is_remote: true, //this.remoteGameTypes.includes(this.classType),
					grade_year: this.gradeYear,
				})
				.then((res) => {
					// 요청이 정상적으로 처리
					// 재신청 가능하게 원래 데이터 지움
					if (this.$store.state.serverStatus === 201) {
						this.initData();
						this.$bvModal.hide('modal-1');
					}
					// 요청 실패
					if (this.$store.state.serverStatus === 400) {
						this.isApplied = false;
						console.log('failed to apply session');
					}
					// 토큰 기간 만료로 요청 실패
					if (this.$store.state.serverStatus === 401) {
						this.$router.push('/');
						window.location.reload();
						alert('다시 로그인하십시오.');
					}
					// 세션을 신청하면 세션 데이터를 다시 가져옴
					this.$emit('createSession');
					return res;
				});
		},
	},
	mounted() {
		// this.getGameTypes()
	},
	components: {
		VueCtkDateTimePicker,
	},
};
</script>

<style>
.input {
	width: 100%;
}
.modal-label {
	margin-top: 1em;
}
#modal-1 .modal-footer {
	border-top: 0;
	display: flex;
	justify-content: center;
}
#modal-1 .modal-footer .row {
	width: 100%;
}
#modal-1 .modal-footer .btn {
	font-weight: bold;
	width: 100%;
	max-width: 100%;
}
</style>
